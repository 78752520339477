import {CommonModule} from '@angular/common';
import {TransferHttpCacheModule} from '@nguniversal/common';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, Routes} from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {LoginModule} from './login/login.module';
import {LoginComponent} from './login/login.component';
import {BrowserModule, BrowserTransferStateModule} from '@angular/platform-browser';
import {LawFinHttpInterceptor} from './_interceptors/law-fin-http-interceptor.service';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import {environment} from '../environments/environment';
import {NgxMaskModule} from 'ngx-mask';
import {MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from '@angular/material/tooltip';

const routes: Routes = [
  {path: '', component: LoginComponent},
  {path: 'pages', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)}
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled'
    }),
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    BrowserTransferStateModule,
    TransferHttpCacheModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSnackBarModule,
    LoginModule,
    NgxMaskModule.forRoot()
  ],
  providers: [
    {provide: 'API_HOST', useValue: environment.apiUrl},
    {provide: HTTP_INTERCEPTORS, useClass: LawFinHttpInterceptor, multi: true},
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {horizontalPosition: 'end', verticalPosition: 'top', duration: 1500}},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: {position: 'above'}},
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {autoFocus: false, width: '550px', maxWidth: '90vw', maxHeight: '80vh', hasBackdrop: true}}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
