import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {AuthenticationService} from '../_services/AuthenticationService';
import {ActivatedRoute, Router} from '@angular/router';
import {GatewayService} from '../../../projects/gateway/src/lib/gateway.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {environment} from '../../../projects/user/src/environments/environment';
import {isPlatformBrowser} from '@angular/common';

@Component({
  selector: 'lc-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm = new FormGroup({
    username: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required])
  });
  private readonly previousUrl;

  constructor(@Inject(PLATFORM_ID) private platformId,
              private route: ActivatedRoute,
              private router: Router,
              private gateway: GatewayService,
              private authService: AuthenticationService) {
    this.previousUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  ngOnInit(): void {
    if (this.authService.isLoginUser()) {
      this.router.navigate(['/pages/users']);
    }
  }

  submitLoginForm() {
    if (!environment.production) {
      const user = {
        basic: window.btoa(`${this.loginForm.value.username}:${this.loginForm.value.password}`)
      };
      localStorage.setItem('principal', JSON.stringify(user));
    }

    if (isPlatformBrowser(this.platformId)) {
      this.gateway.postForm('/login', this.loginForm.value)
        .subscribe(result => {
          if (result) {
            if (this.previousUrl !== '/') {
              this.router.navigate([this.previousUrl]);
            } else {
              this.router.navigate(['/pages/dashboard']);
            }
          } else {
            localStorage.removeItem('principal');
          }
        });
    }
  }
}
