import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
  constructor(@Inject(PLATFORM_ID) private platformId) {
  }

  isLoginUser(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      const sessionAdmin = sessionStorage.getItem('admin');
      return sessionAdmin !== undefined && sessionAdmin !== null;
    }
  }
}
