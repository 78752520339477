<div fxFill>
  <div fxFlexFill fxLayout="row" fxLayoutAlign="center center">
    <mat-card fxFlex="30" fxFlex.lt-sm="85">
      <div class="text-center">
        <img alt="로핀 로고" class="login-bi" src="assets/img/bi/lawcost-bi-a.png"/>
      </div>

      <mat-card-title class="text-center">LAWFIN ADMIN</mat-card-title>
      <form (ngSubmit)="submitLoginForm()" [formGroup]="loginForm" novalidate>
        <mat-card-content fxLayout="column">
          <mat-form-field>
            <mat-label>아이디</mat-label>
            <input autocomplete="username" email="true" formControlName="username" matInput name="username"
                   placeholder="아이디" type="email">
          </mat-form-field>

          <mat-form-field>
            <mat-label>비밀번호</mat-label>
            <input autocomplete="current-password" formControlName="password" matInput name="password"
                   placeholder="비밀번호" type="password">
          </mat-form-field>
        </mat-card-content>

        <mat-card-actions>
          <button fxFlexFill mat-button type="submit">로그인</button>
        </mat-card-actions>
      </form>
    </mat-card>
  </div>
</div>
