import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, finalize} from 'rxjs/internal/operators';
import {throwError} from 'rxjs/internal/observable/throwError';
import {DialogService} from '../../../projects/user/src/app/shared/dialog.service';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {isPlatformBrowser} from '@angular/common';
import {environment} from '../../environments/environment';

@Injectable({providedIn: 'root'})
export class LawFinHttpInterceptor implements HttpInterceptor {
  private count = 0;

  constructor(@Inject(PLATFORM_ID) private platformId,
              private router: Router,
              private dialog: DialogService,
              private spinner: NgxSpinnerService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinner.show();
    this.count++;

    if (!environment.production) {
      if (isPlatformBrowser(this.platformId)) {
        const currentUser = JSON.parse(localStorage.getItem('principal'));

        if (currentUser && currentUser.basic) {
          request = request.clone({
            setHeaders: {
              Authorization: `Basic ${currentUser.basic}`
            },
            withCredentials: true
          });
        } else {
          request = request.clone({withCredentials: true});
        }
      }
    } else {
      request = request.clone({withCredentials: true});
    }

    return next.handle(request)
      .pipe(catchError(errorResponse => {
          const errorMessage = errorResponse.error.text || errorResponse.error.message || errorResponse.error;

          if (errorResponse.status === 0) {
            this.dialog.toast(errorResponse.statusText);
          } else if (errorResponse.status === 400) {
            this.dialog.toast(errorMessage);
          } else if (errorResponse.status === 401 || errorResponse.url.search('/login') > -1) {
            this.dialog.toast(errorMessage);
            this.router.navigate(['/']);
          } else if (errorResponse.status === 403) {
            this.dialog.toast('접근 권한 없음');
            this.router.navigate(['/']);
          } else if (errorResponse.status >= 500) {
            alert(errorMessage);
          }

          return throwError(errorMessage);
        }),
        finalize(() => {
          this.count--;
          if (this.count === 0) {
            this.spinner.hide();
          }
        }));
  }
}
